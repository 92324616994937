import { Global } from '@emotion/react'
import React from 'react'

const Fonts = () => (
    <Global
        styles={`
      @font-face {
        font-family: 'Gotham Black';
        font-style: bold;
        font-weight: 900;
        src: url('./fonts/Gotham-Black.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Gotham Medium';
        font-style: normal;
        font-weight: 400;
        src: url('./fonts/Gotham-Medium.ttf') format('truetype');
      }
       @font-face {
        font-family: 'Gotham Book';
        font-style: normal;
        font-weight: 300;
        src: url('./fonts/Gotham-Book.ttf') format('truetype');
      }
     `}
    />
)

export default Fonts
